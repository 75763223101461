import Link from "next/link";
import dynamic from "next/dynamic";

const FacebookFill = dynamic(() =>
  import("akar-icons").then((mod) => mod.FacebookFill)
);
const LinkedInV2Fill = dynamic(() =>
  import("akar-icons").then((mod) => mod.LinkedInV2Fill)
);
const InstagramFill = dynamic(() =>
  import("akar-icons").then((mod) => mod.InstagramFill)
);
const YoutubeFill = dynamic(() =>
  import("akar-icons").then((mod) => mod.YoutubeFill)
);
const TwitterFill = dynamic(() =>
  import("akar-icons").then((mod) => mod.TwitterFill)
);

const SocialIcon = ({ link, platform, active, size }) => {
  let icon;

  switch (platform) {
    case "facebook":
      icon = <FacebookFill size={size} />;
      break;
    case "instagram":
      icon = <InstagramFill size={size} />;
      break;
    case "linkedin":
      icon = <LinkedInV2Fill size={size} />;
      break;
    case "twitter":
      icon = <TwitterFill size={size} />;
      break;
    case "youtube":
      icon = <YoutubeFill size={size} />;
  }

  if (active) {
    return (
      <Link href={link}>
        <a className="transition-colors duration-100 ease-out hover:text-primaryLight text-neutral-800">
          {icon}
        </a>
      </Link>
    );
  } else {
    return <span className="text-neutral-300">{icon}</span>;
  }
};

export default SocialIcon;
